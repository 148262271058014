import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { SimpleIncomeDialogComponent } from '../wizard-steps/simple-income-dialog/simple-income-dialog.component';
import { IncomeItem, IncomeSubItem } from '../../models/income-item-model';
import { Employment } from '../../models/employment-model';
import { Income } from '../../models/income-model';
import { EmploymentDialogComponent } from '../wizard-steps/employment-dialog/employment-dialog.component';
import { Borrower } from '../../models/borrower-model';
import * as _ from 'lodash';
import { TypeOfIncome } from '../../models/enums/type-of-income.enum';
import { EnumsService } from '../../services/enums.service';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { EmploymentTypeEnum } from '../../models/mortgage.model';
import { FieldConfigUtils } from '../../models/wizard/config/field-config-utils';
import { EmploymentIncomeDialogComponent } from '../wizard-steps/employment-income-dialog/employment-income-dialog.component';
import { Constants } from '../../services/constants';

@Component({
  selector: 'borrower-income',
  templateUrl: 'borrower-income.component.html',
})
export class BorrowerIncomeComponent implements OnInit, AfterViewInit {
  private modalOptions: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    container: '#income-main',
  };

  @Input() borrower!: Borrower;

  @Input()
  fieldConfig: any;

  @Output()
  employmentChanged: EventEmitter<any> = new EventEmitter<any>();

  incomes: IncomeItem[] = [];

  private _employmentBasedIncomeTypes: EnumerationItem[] = [];
  
  private _employmentIncomeTypeValues: string[] = [];

  private _incomeTypes: EnumerationItem[] = [];

  get totalIncome(): number {
    let sum: number = 0;
    this.incomes.forEach((income) => {
      sum = sum + Number(income.monthlyPay);
    });
    return sum;
  }

  isValid(): boolean {
    let result: boolean = true;
    this.incomes.forEach((income) => {
      if (!income.isValid) {
        result = false;
      }
    });
    return result;
  }

  private _nonEmploymentBasedIncomeTypes: TypeOfIncome[] = [];

  constructor(private readonly _modalService: ModalService,
    private readonly _enumsService: EnumsService) {}

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this._incomeTypes = enums[Constants.enumerations.incomeType];
      this.generateEmploymentIncomeTypeValuesList();
      this.prepareEmploymentIncomeTypesList();
      this._nonEmploymentBasedIncomeTypes = this._incomeTypes
        .filter(it => !this._employmentBasedIncomeTypes.includes(it.value)).map(it => it.value);
      this.populateIncomeItems(this.borrower);
    });
  }

  ngAfterViewInit() { }

  refresh = () => {
    this.populateIncomeItems(this.borrower);
  };

  onIncomeDeleted = (income: IncomeItem) => {
    const typeOfIncome = this._employmentBasedIncomeTypes.find(t => t.value === income.type);
    if (typeOfIncome) {
      this.deleteIncomeFromEmployment(income);
      this.employmentChanged.emit();
    } else {
      this.deleteIncomeFromNonEmploymentIncomes(income);
    }
  };

  onEditIncomeItemsClicked = (income: IncomeItem) => {
    const employment = this.borrower.employments.find(e => e.modelGuid === income.employmentGuid);
    this.showEmploymentIncomeItemsDialog(employment);
  }

  onIncomeEditClicked = (income: IncomeItem) => {
    const incomeItemToEdit = this.incomes.find((i) => i.guid === income.guid);
    if (!incomeItemToEdit) {
      return;
    }

    if (this._employmentBasedIncomeTypes.map(t => t.value).includes(incomeItemToEdit.type)) {
      this.handleEmploymentIncomeClick(incomeItemToEdit);
    } else if (this._nonEmploymentBasedIncomeTypes.includes(incomeItemToEdit.type)) {
      this.handleNonEmploymentIncomeClick(incomeItemToEdit);
    }
  };

  private handleEmploymentIncomeClick = (incomeItemToEdit: IncomeItem) => {
    const employmentToEdit = this.borrower.employments.find(
      (e) => e.modelGuid === incomeItemToEdit.employmentGuid
    );
    if (employmentToEdit) {
      if (employmentToEdit.incomes && employmentToEdit.incomes.length) {
        employmentToEdit.businessType = employmentToEdit.incomes[0].businessType;
      }
      this.showEmploymentIncomeDialog(incomeItemToEdit.type, _.cloneDeep(employmentToEdit)
      );
    }
  }

  private handleNonEmploymentIncomeClick = (incomeItemToEdit: IncomeItem) => {
    const incomeToEdit = this.borrower.nonEmploymentIncomes.find(
      (e) => e.modelGuid === incomeItemToEdit.guid
    );
    if (incomeToEdit) {
      const header = this.getHeaderForNonEmploymentIncome(incomeToEdit.typeOfIncome);
      this.showNonEmploymentIncomeDialog(header, "Monthly Income", incomeItemToEdit.type, incomeToEdit);
    }
  }

  private deleteIncomeFromEmployment(income: IncomeItem) {
    const index = this.incomes.indexOf(income);
    const employments = this.borrower.employments;
    if (index >= 0) {
      this.incomes.splice(index, 1);
      const employmentIndexToDelete = employments.findIndex(
        (e) => e.modelGuid === income.employmentGuid
      );
      if (employmentIndexToDelete >= 0) {
        employments.splice(index, 1);
      }
    }
  }

  private deleteIncomeFromNonEmploymentIncomes(income: IncomeItem) {
    const index = this.incomes.indexOf(income);
    const nonEmploymentIncomes = this.borrower.nonEmploymentIncomes;
    if (index >= 0) {
      this.incomes.splice(index, 1);
      const incomeToDelete = nonEmploymentIncomes.find(
        (i) => i.modelGuid === income.guid
      );
      if (incomeToDelete) {
        const index = nonEmploymentIncomes.indexOf(incomeToDelete);
        if (index >= 0) {
          nonEmploymentIncomes.splice(index, 1);
        }
      }
    }
  }

  private isEmploymentValidV2(employment: Employment): boolean {
    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.employer') &&
      (!employment.employer || employment.employer.length == 0)) {
      return false;
    }

    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.employmentType') && (employment.employmentType == null)) {
      return false;
    }

    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.startDate') && !employment.startDate) {
      return false;
    }

    if(!(employment.employer && employment.employer == "Retired" && employment.employmentType == EmploymentTypeEnum.CurrentEmployer)){

      if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.position') && (!employment.position || employment.position.length == 0)) {
        return false;
      }
      if (employment.isPrimary == null) {
        return false;
      }

      if (employment.incomes == null || employment.incomes.length == 0) {
        return false;
      }
      if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.yearsInLineOfWork') &&
        (employment.yearsInLineOfWork == null ||
        employment.yearsInLineOfWork <= 0)
      ) {
        return false;
      }

      // if (typeOfIncome && typeOfIncome !== TypeOfIncome.ContractBasis) {
      //   if (
      //     !employment.address.address1 ||
      //     employment.address.address1.length === 0
      //   ) {
      //     return false;
      //   }
      //   if (!employment.address.city || employment.address.city.length === 0) {
      //     return false;
      //   }
      //   if (!employment.address.state || employment.address.state.length === 0) {
      //     return false;
      //   }
      //   if (
      //     !employment.address.zipCode ||
      //     employment.address.zipCode.length === 0
      //   ) {
      //     return false;
      //   }
      // }

    }
    return true;
  }

  private isEmploymentValid(
    typeOfIncome: TypeOfIncome | undefined,
    employment: Employment
  ): boolean {
    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.employer') &&
      (!employment.employer || employment.employer.length == 0)) {
      return false;
    }

    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.employmentType') && (employment.employmentType == null)) {
      return false;
    }

    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.startDate') && !employment.startDate) {
      return false;
    }

    if(!(employment.employer && employment.employer == "Retired" && employment.employmentType == EmploymentTypeEnum.CurrentEmployer)){

      if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.position') && (!employment.position || employment.position.length == 0)) {
        return false;
      }
      if (employment.isPrimary == null) {
        return false;
      }

      if (employment.incomes == null || employment.incomes.length == 0) {
        return false;
      }
      if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.yearsInLineOfWork') &&
        (employment.yearsInLineOfWork == null ||
        employment.yearsInLineOfWork <= 0)
      ) {
        return false;
      }

      // if (typeOfIncome && typeOfIncome !== TypeOfIncome.ContractBasis) {
      //   if (
      //     !employment.address.address1 ||
      //     employment.address.address1.length === 0
      //   ) {
      //     return false;
      //   }
      //   if (!employment.address.city || employment.address.city.length === 0) {
      //     return false;
      //   }
      //   if (!employment.address.state || employment.address.state.length === 0) {
      //     return false;
      //   }
      //   if (
      //     !employment.address.zipCode ||
      //     employment.address.zipCode.length === 0
      //   ) {
      //     return false;
      //   }
      // }

    }
    return true;
  }

  private isIncomeValid(income: Income): boolean {
    if (income.typeOfIncome == TypeOfIncome.OtherTypesOfIncome) {
      if (
        !income.typeOfIncomeOtherDescription ||
        income.typeOfIncomeOtherDescription.length == 0
      ) {
        return false;
      }
    }
    if (!income.monthlyIncome || income.monthlyIncome <= 0) {
      return false;
    }
    return true;
  }

  private toEmploymentIncomeItem = (
    employment: Employment
  ): IncomeItem => {

    const incomeItem = new IncomeItem(
      TypeOfIncome.Base,
      0,
      this.isEmploymentValidV2(employment),
      employment.position,
      employment.startDate!,
    );
    incomeItem.employmentGuid = employment.modelGuid;
    incomeItem.isSelfEmployed = employment.selfEmployed || false;
    if (employment.incomes) {
      if (employment.incomes.length === 1) {
        incomeItem.employmentGuid = employment.modelGuid;
        incomeItem.guid = employment.incomes[0].modelGuid;
        incomeItem.type = employment.incomes[0].typeOfIncome;
      }
      employment.incomes.forEach((income) => {
        if (income) {
          const subIncomeItem = new IncomeSubItem();
          subIncomeItem.type = income.typeOfIncome!;
          subIncomeItem.monthlyPay = income.monthlyIncome!;
          subIncomeItem.guid = income.modelGuid;
          incomeItem.incomeBreakdown.push(subIncomeItem);
        }
      });
    }
    
    incomeItem.name = employment.employer;
    incomeItem.employmentGuid = employment.modelGuid;
    incomeItem.monthlyPay = incomeItem.incomeBreakdown.reduce((total, subIncome) => total + Number(subIncome.monthlyPay), 0);
    return incomeItem;
  };

  private toIncomeItemNonEmployment = (income: Income): IncomeItem => {
    const incomeItem = new IncomeItem(
      income.typeOfIncome!,
      income.monthlyIncome!,
      this.isIncomeValid(income)
    );
    incomeItem.name = this.typeOfIncomeDisplayText(income.typeOfIncome!);
    incomeItem.id = income.incomeId;
    if (income.typeOfIncome == TypeOfIncome.OtherTypesOfIncome) {
      incomeItem.position = income.typeOfIncomeOtherDescription;
    }
    incomeItem.guid = income.modelGuid;
    return incomeItem;
  };

  private typeOfIncomeDisplayText = (typeOfIncome: TypeOfIncome): string => {
    const incomeType = this._incomeTypes.find(it => it.value === typeOfIncome);
    if (incomeType) {
      return incomeType.name;
    }
    return typeOfIncome;
  };

  private populateIncomeItems(borrower: Borrower) {
    this.incomes = [];
    borrower.employments.forEach((employment) => {
      const employmentIncomeItem = this.toEmploymentIncomeItem(employment);
      this.incomes.push(employmentIncomeItem);
    });

    borrower.nonEmploymentIncomes.forEach((nonEmploymentIncome) =>
      this.incomes.push(this.toIncomeItemNonEmployment(nonEmploymentIncome))
    );
  }

  private showEmploymentIncomeItemsDialog = (employment: Employment) => {
    const modal = this._modalService.show(
      EmploymentIncomeDialogComponent,
      this.modalOptions
    );
    modal.componentInstance.employment = employment;
    modal.result.then(
      (updatedEmployment) => {
        const updatedEmploymentIncomeItem = this.toEmploymentIncomeItem(updatedEmployment);
        const indexOfEmployment = this.borrower.employments.findIndex(e => e.modelGuid === updatedEmployment.modelGuid);
        if (indexOfEmployment >= 0) {
          this.borrower.employments[indexOfEmployment] = updatedEmployment;
        }
        const index = this.incomes.findIndex((i) => i.employmentGuid === updatedEmployment.modelGuid);
        if (index >= 0) {
          this.incomes[index] = updatedEmploymentIncomeItem;
        }
        //this.incomes = incomes;
      },
      (error) => { }
    );
  }

  private showEmploymentIncomeDialog(
    typeOfIncome: TypeOfIncome,
    employment?: Employment
  ) {
    const modal = this._modalService.show(
      EmploymentDialogComponent,
      this.modalOptions
    );
    modal.componentInstance.typeOfIncome = typeOfIncome;
    modal.componentInstance.fieldsToConfig = this.fieldConfig;
    if (employment) {
      modal.componentInstance.employment = employment;
    } else if (
      typeOfIncome == TypeOfIncome.ContractBasis ||
      typeOfIncome === TypeOfIncome.SelfEmploymentIncome
    ) {
      modal.componentInstance.employment.selfEmployed = true;
    }
    modal.result.then(
      (employmentAndIncomeType) => {
        this.onSaveClickedOnEmploymentModalForEdit(employmentAndIncomeType.employment, typeOfIncome);
      },
      (error) => { }
    );
  }

  private onSaveClickedOnIncomeModalForEdit = (
    income: Income,
    typeOfIncome: TypeOfIncome
  ): void => {
    const nonEmploymentIncomes = this.borrower.nonEmploymentIncomes;
    if (!nonEmploymentIncomes) {
      return;
    }
    const existingNonEmploymentIncome = nonEmploymentIncomes?.find(
      (e) => e.modelGuid === income.modelGuid
    );
    if (!existingNonEmploymentIncome) {
      return;
    }
    const index = nonEmploymentIncomes?.indexOf(existingNonEmploymentIncome);
    nonEmploymentIncomes[index] = income;

    var incomeItem = this.incomes.find((i) => i.guid === income.modelGuid);
    if (incomeItem) {
      const index = this.incomes.indexOf(incomeItem);
      const updatedIncomeItem = this.toIncomeItemNonEmployment(income);
      this.incomes[index] = updatedIncomeItem;
    }
  };

  private onSaveClickedOnEmploymentModalForEdit = (
    employment: Employment,
    typeOfIncome: TypeOfIncome
  ): void => {
    const employments = this.borrower.employments;
    if (employments) {
      const existingEmploymentIndex = employments?.findIndex(
        (e) => e.modelGuid === employment.modelGuid
      );
      if (existingEmploymentIndex >= 0) {
        const monthlyIncome = (typeOfIncome === TypeOfIncome.SelfEmploymentIncome)
          ? employment.selfEmploymentMonthlyIncomeOrLoss : employment.monthlyIncome;

        employments[existingEmploymentIndex] = employment;

        // Edit always edits the base income
        let baseIncome = employment.incomes.find(i => i.typeOfIncome === TypeOfIncome.Base);
        if (!baseIncome) {
          baseIncome = new Income();
          employment.incomes.push(baseIncome);
        }
        baseIncome.monthlyIncome = Number(monthlyIncome);
        baseIncome.businessType = employment.businessType;
        baseIncome.typeOfIncome = typeOfIncome;

        var incomeItem = this.incomes.find(
          (i) => i.employmentGuid === employment.modelGuid
        );
        if (incomeItem) {
          const index = this.incomes.indexOf(incomeItem);
          const updatedIncomeItem = this.toEmploymentIncomeItem(
            employment
          );
          this.incomes[index] = updatedIncomeItem;
        }
        this.employmentChanged.emit();
      }
    }
  };

  private showNonEmploymentIncomeDialog = (
    header: string,
    label: string,
    typeOfIncome: TypeOfIncome,
    income?: Income
  ) => {
    const modal = this._modalService.show(
      SimpleIncomeDialogComponent,
      this.modalOptions
    );
    modal.componentInstance.header = header;
    modal.componentInstance.label = label;
    if (income) {
      modal.componentInstance.income = income;
    } else {
      modal.componentInstance.typeOfIncome = typeOfIncome;
    }
    modal.result.then(
      (income) => {
        this.onSaveClickedOnIncomeModalForEdit(income, typeOfIncome);
      },
      (error) => { }
    );
  };

  private getHeaderForNonEmploymentIncome = (typeOfIncome: TypeOfIncome): string => {
    const type = this._incomeTypes.find(it => it.value === typeOfIncome);
    if (type) {
      return type.name;
    }
    return 'Other';
  }

  private generateEmploymentIncomeTypeValuesList = () => {
    this._employmentIncomeTypeValues = [
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryCombatPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryFlightPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryHazardPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryOverseasPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryPropPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryClothesAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryRationsAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryVariableHousingAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryQuartersAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.OtherTypesOfIncome),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.ContractBasis),
    ]
  }

  private prepareEmploymentIncomeTypesList = () => {
    const employmentIncomeTypes = [];
    this._employmentIncomeTypeValues.forEach(type => {
      const incomeType = this._incomeTypes.find(t => t.value === type);
      if (incomeType) {
        employmentIncomeTypes.push(incomeType);
      }
    });
    this._employmentBasedIncomeTypes = employmentIncomeTypes;
  }
}
