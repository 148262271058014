<div class="card">
  <div
    class="card-header d-flex align-items-center justify-content-center"
    style="min-height: 50px; padding-top: 6px"
  >
    <h5 class="opacity-80">Loan Actions</h5>
  </div>
  <div class="card-body">
    <div class="row btn-loan-actions">
      <a
        *ngIf="hasPrequalDetails"
        href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4"
        (click)="openGeneratePrequalLetterDialog()"
        ><i class="text-white fa fa-file-alt fs-3" style="margin-right: 13px;"></i>Generate
        Preapproval Letter
      </a>

      <a *ngIf="application.companyId != 252"
        href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4"
        (click)="openMortgageCalculatorModal()"
        ><i class="text-white fa fa-calculator fs-3" style="margin-right: 8.5px;"></i>
        Open Payment Calculator
      </a>

      <a *ngIf="!isAgent && hasAssetVerificationTasks"
        href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4"
        (click)="onOpenAssetVerificationClicked()"
        ><i class="text-white fa fa-university fs-3" style="margin-right: 6.5px;"></i>
        Verify Your Assets
      </a>

      <a *ngIf="!isAgent && hasIncomeVerificationTasks"
        href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4"
        (click)="onOpenIncomeVerificationClicked()"
        ><i class="text-white fas fa-money-bill me-1 fs-3"></i>
        Verify Your Emp./Income
      </a>
    </div>
  </div>
</div>
