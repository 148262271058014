import { AfterViewInit, Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import * as _ from 'lodash';
import { CheckListsStep } from '../../../models/wizard/checklists-step.model';
import { ChecklistsService } from '../../../services/admin/checklists.service';
import { forkJoin, Observer, Subscription } from 'rxjs';
import { CheckListConfig, QuestionSummary } from '../../../models/checklists/config/checklist-config.model';
import { ChecklistQuestion } from '../../../models/checklists/borrower-flow/checklist-question.model';
import { BorrowerChecklistsComponent, BorrowerQuestionCountStatusEvent, SelectedAnswer } from './borrower-checklist/borrower-checklists.component';
import { Borrower } from '../../../models/borrower-model';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'checklists-step',
  templateUrl: 'checklists-step.component.html',
})
export class ChecklistsStepComponent
  extends WizardStepComponentBase<CheckListsStep>
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('borrowerChecklists') borrowerChecklists:
    | QueryList<BorrowerChecklistsComponent>
    | undefined;

  mortgage: MortgageApplication;

  isLoadingChecklists: boolean = true;
  showSpinnerOnMainContent: boolean = true;

  checklists: CheckListConfig[] = [];

  selectedChecklistIds: number[] = [];

  questionMetaData: QuestionSummary[] = [];

  questionsByChecklist: Map<number, ChecklistQuestion[]> = new Map<
    number,
    ChecklistQuestion[]
  >();

  isSavingAnswer: boolean = false;

  showNextButton: boolean = true;
  showPreviousButton: boolean = false;

  currentBorrowerIndex: number = 0;

  borrowersToRunTroughTheChecklist: Borrower[] = [];

  private _eventSubscription: Subscription;

  private _noOfBorrowersReportedThereAreNoQuestionsToAnswer: number = 0;

  get canMoveToNextStep(): boolean {
    if (this.isEditEnabled) {
      return true;
    }
    return !this.showNextButton;
  }

  constructor(
    private readonly _checklistsService: ChecklistsService,
    private readonly _injector: Injector
  ) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.mortgage = this.mortgageApplication;
    this.borrowersToRunTroughTheChecklist = this.borrowersToApplyFor();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.isEditEnabled && this.step) {
      this.loadChecklistsForAdminToConfigure();
    } else if (!this.isEditEnabled && this.step) {
      this.loadChecklistsForBorrowerToGoThrough();
      this.loadChecklistConfigsForAllSelectedChecklists();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.borrowerChecklists && !this.isEditEnabled) {
      if (!this.borrowersToRunTroughTheChecklist.length) {
        if (this.cameHereWithBackNavigation) {
          this.wizardFlowService.navigateBackward();
        } else {
          this.onNextClicked();
        }
        return;
      }
      this._eventSubscription = this.borrowerChecklists.changes.subscribe((bc) => {
        if (bc._results && bc._results.length > 0) {
          setTimeout(() => {
            this.decideNextAndPreviousButtonVisibleStates();
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this._eventSubscription) {
      this._eventSubscription.unsubscribe();
    }
  }

  onNextQuestionClicked = () => {
    const currentBorrowerChecklistComponent = this.borrowerChecklists.toArray()[this.currentBorrowerIndex];
    if (currentBorrowerChecklistComponent.atTheEndOfChecklist &&
      this.currentBorrowerIndex !==
      this.borrowersToRunTroughTheChecklist.length - 1) {
      this.currentBorrowerIndex += 1;
    } else {
      currentBorrowerChecklistComponent.gotoNextQuestion();
    }
    this.decideNextAndPreviousButtonVisibleStates();
  };

  onPreviousQuestionClicked = () => {
    const currentBorrowerChecklistComponent =
      this.borrowerChecklists.toArray()[this.currentBorrowerIndex];
    if (
      currentBorrowerChecklistComponent.atTheBeginningOfChecklist &&
      this.currentBorrowerIndex !== 0
    ) {
      this.currentBorrowerIndex -= 1;
    } else {
      currentBorrowerChecklistComponent.gotoPreviousQuestion();
    }
    this.decideNextAndPreviousButtonVisibleStates();
  };

  onSelectedChecklistsChanged = (selectedCheckListIds: number[]) => {
    this.step.selectedChecklistIds = selectedCheckListIds;
  };

  onNextClicked() {
    const valid = this.validate();
    if (!valid) {
      return;
    }
    const apiCalls: any[] = [];
    const answersToBulkUpdate: any = {};
    this.borrowerChecklists.forEach((c) => {
      this.selectedChecklistIds.forEach((checklistId) => {
        const checklist = this.checklists.find(
          (c) => c.checklistId === checklistId
        );
        const answersForChecklist = c.borrowersAnswersToQuestions[checklistId];
        if (answersForChecklist) {
          const checklistQuestions = c.questionsByChecklist.get(checklistId);
          const questionIds = Object.keys(answersForChecklist);
          questionIds.forEach((questionId) => {
            const checklistQuestion = checklistQuestions.find(
              (q) => q.questionId.toString() === questionId
            );
            if (checklistQuestion) {
              const selectedAnswer = answersForChecklist[
                questionId
              ] as SelectedAnswer;

              let borrowerIdToMatch: number | undefined | null = undefined;

              if (selectedAnswer) {
                if (selectedAnswer.borrowerId) {
                  borrowerIdToMatch = c.borrower.contactId;
                } else if (selectedAnswer.questionType === 'TriggerOnly' && c.borrower) {
                  borrowerIdToMatch = c.borrower.contactId;
                }
              }

              const submittedAnswers = (
                checklistQuestion.submittedAnswers &&
                  checklistQuestion.submittedAnswers.length
                  ? checklistQuestion.submittedAnswers
                  : []
              ).filter(
                (sa) =>
                  sa.applicationId === this.mortgageApplication.applicationId &&
                  sa.borrowerId === borrowerIdToMatch &&
                  sa.questionId.toString() === questionId
              );

              if (!selectedAnswer) {
                // No answer selected for this question, whatever answer is in there, it needs to be deleted
                submittedAnswers.forEach((sa) => {
                  apiCalls.push(
                    this._checklistsService.deleteQuestionAnswer(
                      checklistId,
                      sa.checklistAnswerId
                    )
                  );
                });
                return;
              }

              if (!submittedAnswers.length && selectedAnswer.questionType === 'TriggerOnly') {
                // When inserting the answer, borrower id and question answer id are always null.
                // App id, checklist version id, question id are required.
                const answerToUpdate = {
                  applicationId: this.mortgageApplication.applicationId,
                  borrowerId: borrowerIdToMatch,
                  questionId: Number(questionId),
                  questionAnswerId: null,
                  checklistVersionId:
                    checklist?.currentVersion.checklistVersionId,
                }
                if (answersToBulkUpdate[checklistId]) {
                  answersToBulkUpdate[checklistId].push(answerToUpdate);
                } else {
                  answersToBulkUpdate[checklistId] = [answerToUpdate];
                }
                // apiCalls.push(
                //   this._checklistsService.insertQuestionAnswer(checklistId, {
                //     applicationId: this.mortgageApplication.applicationId,
                //     borrowerId: borrowerIdToMatch,
                //     questionId: Number(questionId),
                //     questionAnswerId: null,
                //     checklistVersionId:
                //       checklist?.currentVersion.checklistVersionId,
                //   })
                // );
                return;
              }
              submittedAnswers.forEach((sa) => {
                if (sa.questionAnswerId) {
                  // Submitted answer was a selection from multiple choices
                  const selectedChoices =
                    selectedAnswer.choicesSelected &&
                      selectedAnswer.choicesSelected.length
                      ? selectedAnswer.choicesSelected
                      : [];

                  const alreadySubmittedAnswerSelectedAgain =
                    selectedChoices.find((sc) => sc === sa.questionAnswerId);
                  if (!alreadySubmittedAnswerSelectedAgain) {
                    // This answer that was submitted before is not among selected answers this time around - need to delete it
                    apiCalls.push(
                      this._checklistsService.deleteQuestionAnswer(
                        checklistId,
                        sa.checklistAnswerId
                      )
                    );
                  }
                } else {
                  // Submitted answer was a free form text
                  // If the current answer is like that too, it needs to be updated
                  if (
                    selectedAnswer.singleAnswer &&
                    !selectedAnswer.choicesSelected.length
                  ) {
                    sa.answerText = selectedAnswer.singleAnswer;
                    if (answersToBulkUpdate[checklistId]) {
                      answersToBulkUpdate[checklistId].push(sa);
                    } else {
                      answersToBulkUpdate[checklistId] = [sa];
                    }
                  }
                }
              });

              const selectedChoices =
                selectedAnswer.choicesSelected &&
                  selectedAnswer.choicesSelected.length
                  ? selectedAnswer.choicesSelected
                  : [];
              selectedChoices.forEach((selectedChoice) => {
                const selectedAnswerAmongAlreadySubmitted =
                  submittedAnswers.find(
                    (sa) => sa.questionAnswerId === selectedChoice
                  );
                if (!selectedAnswerAmongAlreadySubmitted) {
                  // Newly selected answer is not among already submitted answers - need to insert it
                  const answerToUpdate = {
                    applicationId: this.mortgageApplication.applicationId,
                    borrowerId: borrowerIdToMatch,
                    questionId: Number(questionId),
                    questionAnswerId: selectedChoice,
                    checklistVersionId:
                      checklist?.currentVersion.checklistVersionId,
                  }
                  if (answersToBulkUpdate[checklistId]) {
                    answersToBulkUpdate[checklistId].push(answerToUpdate);
                  } else {
                    answersToBulkUpdate[checklistId] = [answerToUpdate];
                  }
                }
              });
              if (
                (!selectedAnswer.choicesSelected ||
                  !selectedAnswer.choicesSelected.length) &&
                selectedAnswer.singleAnswer
              ) {
                const existingSubmittedAnswerWithFreeFormText =
                  submittedAnswers.find((sa) => !sa.questionAnswerId);
                if (!existingSubmittedAnswerWithFreeFormText) {
                  const answerToUpdate = {
                    applicationId: this.mortgageApplication.applicationId,
                    borrowerId: borrowerIdToMatch,
                    questionId: Number(questionId),
                    questionAnswerId: null,
                    answerText: selectedAnswer.singleAnswer,
                    checklistVersionId:
                      checklist.currentVersion.checklistVersionId,
                  }
                  if (answersToBulkUpdate[checklistId]) {
                    answersToBulkUpdate[checklistId].push(answerToUpdate);
                  } else {
                    answersToBulkUpdate[checklistId] = [answerToUpdate];
                  }
                } else {
                  existingSubmittedAnswerWithFreeFormText.answerText =
                    selectedAnswer.singleAnswer;
                    if (answersToBulkUpdate[checklistId]) {
                      answersToBulkUpdate[checklistId].push(existingSubmittedAnswerWithFreeFormText);
                    } else {
                      answersToBulkUpdate[checklistId] = [existingSubmittedAnswerWithFreeFormText];
                    }
                }
              }
            }
          });
        }
      });
    });
    const observer: Observer<any[]> = {
      next: (response: any[]): void => {
        super.onNextClicked();
      },
      error: (error: any): void => {
        // const message =
        //   error && error.error
        //     ? error.error.message
        //     : 'An error accurred saving your answers.';
        // this.showError(new ErrorMessage('Error!', message));
        this.stopSpinner();
      },
      complete: (): void => {
        //this.stopSpinner();
      },
    };

    Object.keys(answersToBulkUpdate).forEach((checklistId) => {
      apiCalls.push(
        this._checklistsService.bulkUpdateQuestionAnswers(
          Number(checklistId),
          answersToBulkUpdate[checklistId]
        )
      );
    });

    if (apiCalls.length) {
      this.startSpinner();
      forkJoin(apiCalls).subscribe(observer);
    } else {
      super.onNextClicked();
    }
  }

  onBorrowerNotifiedThatAllQuestionsAreTriggerOnly = (e: BorrowerQuestionCountStatusEvent) => {
    if (e.noQuestionsToAnswer) {
      this._noOfBorrowersReportedThereAreNoQuestionsToAnswer += 1;
    }

    console.log("noOfBorrowersReportedThereAreNoQuestionsToAnswer: " + this._noOfBorrowersReportedThereAreNoQuestionsToAnswer);
    if (this._noOfBorrowersReportedThereAreNoQuestionsToAnswer === this.borrowersToRunTroughTheChecklist.length) {
      setTimeout(() => {
        if (this.cameHereWithBackNavigation) {
          this.wizardFlowService.navigateBackward();
        } else {
          this.onNextClicked();
        }
      })
    } else {
      this.showSpinnerOnMainContent = false;
    }
  }

  private loadChecklistsForAdminToConfigure = () => {
    this.selectedChecklistIds = this.step.selectedChecklistIds ? [...this.step.selectedChecklistIds] : [];
    this.isLoadingChecklists = true;
    this.startSpinner();
    const observer: Observer<CheckListConfig[]> = {
      next: (checklists: CheckListConfig[]): void => {
        this.checklists = checklists;
      },
      error: (err: any): void => { },
      complete: (): void => {
        this.stopSpinner();
        this.isLoadingChecklists = false;
        this.showSpinnerOnMainContent = false;
      },
    };
    this._checklistsService.getChecklists().subscribe(observer);
  };

  private loadChecklistConfigsForAllSelectedChecklists = () => {
    const apiCalls: any[] = [];

    if (!this.step.selectedChecklistIds) {
      return;
    }

    this.step.selectedChecklistIds.forEach((checklistId) => {
      const apiCall = this._checklistsService.getChecklist(checklistId);
      apiCalls.push(apiCall);
    });
    const observer: Observer<any[]> = {
      next: (response: CheckListConfig[]): void => {
        this.checklists = response;
      },
      error: (err: any): void => {
        this.stopSpinner();
      },
      complete: (): void => {
        this.stopSpinner();
      },
    };
    if (apiCalls.length) {
      this.startSpinner();
      forkJoin(apiCalls).subscribe(observer);
    }
  };

  private loadChecklistsForBorrowerToGoThrough = () => {
    this.selectedChecklistIds = this.step.selectedChecklistIds ? [...this.step.selectedChecklistIds] : [];

    if (!this.selectedChecklistIds || !this.selectedChecklistIds.length) {
      if (this.cameHereWithBackNavigation) {
        this.wizardFlowService.navigateBackward();
      } else {
        this.wizardFlowService.navigateForward();
      }
      return;
    }
    const apiCalls: any[] = [];
    this.selectedChecklistIds.forEach((checklistId) => {
      const apiCall =
        this._checklistsService.getChecklistQuestionsForApplication(
          checklistId,
          this.mortgage.applicationId
        );
      apiCalls.push(apiCall);
    });

    apiCalls.push(this._checklistsService.getAllQuestionsWithAnswers());

    const observer: Observer<any[]> = {
      next: (response: any[]): void => {
        for (let i = this.selectedChecklistIds.length - 1; i >= 0; i--) {
          const questions = response[i].questions.filter((q) => q.isValid);
          if (questions.length) {
            // Ignore checklists with no questions
            this.questionsByChecklist.set(
              this.selectedChecklistIds[i],
              questions
            );
          } else {
            this.selectedChecklistIds.splice(i, 1);
          }
        }
        if (!this.questionsByChecklist.size) {
          if (this.cameHereWithBackNavigation) {
            this.wizardFlowService.navigateBackward();
          } else {
            this.wizardFlowService.navigateForward();
          }
          return;
        }
        this.questionMetaData = response[response.length - 1];
      },
      error: (err: any): void => {
        this.stopSpinner();
        this.isLoadingChecklists = false;
      },
      complete: (): void => {
        this.stopSpinner();
        this.isLoadingChecklists = false;
      },
    };
    if (apiCalls.length) {
      this.startSpinner();
      this.isLoadingChecklists = true;
      forkJoin(apiCalls).subscribe(observer);
    }
  };

  private decideNextAndPreviousButtonVisibleStates = () => {
    const currentBorrowerChecklistComponent =
      this.borrowerChecklists.toArray()[this.currentBorrowerIndex];
    this.showNextButton = !(
      currentBorrowerChecklistComponent.atTheEndOfChecklist &&
      this.currentBorrowerIndex === this.borrowersToRunTroughTheChecklist.length - 1
    );

    this.showPreviousButton = !(
      currentBorrowerChecklistComponent.atTheBeginningOfChecklist &&
      this.currentBorrowerIndex === 0
    );
  };

  private validate = (): boolean => {
    let valid = true;
    this.borrowerChecklists.forEach((c) => {
      valid = valid && c.validate();
    });
    return valid;
  };
}



